import React, { Suspense, lazy, useContext } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import {PrivacyPolicy, TermsOfService, DataDeletion} from '../components/policies/mrktapp'
import AuthContext from "context/auth-context";

import Header from "components/home/Header";
import MarketingPage from "../view/Marketing/Home";

const MarketLayout = ({ children }) => {
  return <main>{children}</main>;
};

const BasicLayout = ({ children }) => {
  return (
    <section className="app-layout">
      <Header />
      {children}
    </section>
  );
};

const ProtectedRoute = ({ children }) => {
  return <Navigate to="/" />;
};

const routesConfig = [
  {
    exact: true,
    path: '/',
    layout: MarketLayout,
    component: lazy(() => import('../view/Marketing/Home')),
  },
  {
      exact: true,
      path: '/pricing',
      layout: MarketLayout,
      component: lazy(() => import('../view/Price/PricingPage')),
  },
  {
      exact: true,
      path: '/portal',
      layout: BasicLayout,
      component: lazy(() => import('../view/Login')),
  },
  {
      exact: true,
      path: '/login',
      layout: BasicLayout,
      component: lazy(() => import('../view/Login')),
  },
  {
      exact: true,
      path: '/register',
      layout: BasicLayout,
      component: lazy(() => import('../view/Login')),
  },
  {
      exact: true,
      path: '/appointment',
      layout: BasicLayout,
      component: lazy(() => import('./calendly/Calendly')),
  },
  {
      exact: true,
      path: '/newProject',
      layout: BasicLayout,
      component: lazy(() => import('../view/NewProject')),
  },
  {
      exact: true,
      path: '/payment',
      layout: BasicLayout,
      component: lazy(() => import('../view/Payment')),
  },
  {
      exact: true,
      path: '/about',
      layout: BasicLayout,
      component: lazy(() => import('../view/About')),
  },
  {
      exact: true,
      path: '/projects-view',
      layout: BasicLayout,
      component: lazy(() => import('../view/ProjectsView')),
  },
  {
      exact: true,
      path: '/documents',
      layout: BasicLayout,
      component: lazy(() => import('../view/ProjectDocuments')),
  },
  {
    exact: true,
    path: '/history',
    layout: BasicLayout,
    component: lazy(() => import('../view/History')),
},
{
  exact: true,
  path: '/apps/MRKT/privacy-policy',
  layout: MarketLayout,
  component: PrivacyPolicy,
},
{
  exact: true,
  path: '/apps/MRKT/terms-of-service',
  layout: MarketLayout,
  component: TermsOfService,
},
{
  exact: true,
  path: '/apps/MRKT/data-deletion',
  layout: MarketLayout,
  component: DataDeletion,
},
];

const renderRoutes = (routes, authCtx) => {
  const authNeedRoutes = [
    '/appointment',
    '/newProject',
    '/questionnaire',
    '/payment',
    '/about',
    '/projects-view',
    '/documents',
    'history'
  ];
  return (
    <>
      {routes ? (
        <Suspense fallback={<MarketingPage />}>
          <Routes>
            {routes.map((route, i) => {
              const Layout = route.layout || React.Fragment;
              let Component = route.component;
              let path = route.path;
              let checkAuth = true;
              if (route.path === '/documents') {
                if (authCtx.cookies.user && authCtx.cookies.user.role === 'ADMIN') {
                  Component = lazy(() => import('../view/ProjectDocumentsAdmin'))
                } else {
                  Component = lazy(() => import('../view/ProjectDocuments'))
                }
              }
              if (authNeedRoutes.indexOf(route.path) >= 0) {
                if (authCtx.isLoggedIn === false) {
                  checkAuth = false;
                }
              }
              return (
                <Route
                  key={i}
                  path={path}
                  exact={route.exact}
                  element={checkAuth ? (<Layout>{route.routes? renderRoutes(route.routes): <Component/>}</Layout>) : (<ProtectedRoute><Layout>{route.routes? renderRoutes(route.routes): <Component/>}</Layout></ProtectedRoute>)}
                />
              );
            })}
          </Routes>
        </Suspense>
      ) : null}
    </>
  )
}


function Navigators() {
  const authCtx = useContext(AuthContext);
  return renderRoutes(routesConfig, authCtx);
}

export default Navigators;
