import { useState, useEffect, useContext, createContext } from "react";
import Axios from "axios";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("yWi2kgDl-TMKQjC09");
const QuestionnaireProviderContext = createContext();

export function useQuestionnaireProvider() {
  return useContext(QuestionnaireProviderContext);
}

export default function QuestionnaireProvider({ children }) {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [event, setEvent] = useState("");
  const [answers, setAnswers] = useState([]);
  const [formComplete, setFormComplete] = useState(false);
  const [guest, setGuest] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [meeting, setMeeting] = useState("");
  const [Loader, setLoader] = useState(false);
  const [oneTimeAPICallForUser, setOneTimeAPICallForUser] = useState(true);

  // SUBMIT QUESTION ANSWER FORM
  const handleSubmit = (e, question, options) => {
    e.preventDefault();
    let answer;
    if (questionNumber === 5) {
      answer = options.map((option) => ({
        question: option,
        answer: e.target[option].value,
      }));
    } else {
      answer = e.target.answer.value;
    }
  
    setAnswers((prev) => {
      const existingAnswer = prev.find((obj) => obj.question === question);
      if (existingAnswer) {
        existingAnswer.answer = answer;
        return [...prev];
      }
      return [...prev, { question: question, answer: answer }];
    });
  
    if (questionNumber === 3) {
      setFormComplete(true);
      setQuestionNumber(4);
      return;
    } else {
      setQuestionNumber((prev) => prev + 1);
    }
  };
  // useEffect(() => {
  //   if (
  //     guest &&
  //     guest.data.resource.event_guests &&
  //     guest.data.resource.event_guests[0]
  //   ) {
  //     setGuestEmail(guest.data.resource.event_guests[0].email);
  //   }
  // }, [guest]);
  // DECREASE QUESTION
  const decreaseQuestion = () => {
    setQuestionNumber((prev) => prev - 1);
  };
  // CALANDLY EVENT HANDLER
  useEffect(() => {
    if (event && event.data && event.data.payload.event.uri) {
      // TOKEN
      const token =
        "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjI3NDkwMTc1LCJqdGkiOiJkYmIyMmI0Mi0xNmE2LTQ0ZWEtYTE2Zi1iZDcwZWY3OTlkYzAiLCJ1c2VyX3V1aWQiOiJFRkZEVTMzUUkyVjVYQlQ0In0.rajL6MEkX1xRvhy1OKjwpuCHBQtuwA9vXcPzKiNUFk4";
      const headers = {
        authorization: token,
      };
      // EVENT API CALL
      Axios.get(event.data.payload.event.uri, { headers })
        .then((res) => {
          setMeeting(res.data.resource.start_time);
          if (
            res.data.resource.event_guests &&
            res.data.resource.event_guests[0]
          ) {
            setGuest(res.data.resource.event_guests[0].email);
          }
        })
        .catch((err) => {});
      setLoader(true);
      setModalShow(true);

      // INVITEE API CAL
    }
    // SEND EMAIL TO CLIENT

    // SEND EMAIL TO TERMNL
  }, [event, guest, answers, meeting]);

  useEffect(() => {
    const token =
      "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjI3NDkwMTc1LCJqdGkiOiJkYmIyMmI0Mi0xNmE2LTQ0ZWEtYTE2Zi1iZDcwZWY3OTlkYzAiLCJ1c2VyX3V1aWQiOiJFRkZEVTMzUUkyVjVYQlQ0In0.rajL6MEkX1xRvhy1OKjwpuCHBQtuwA9vXcPzKiNUFk4";
    const headers = {
      authorization: token,
    };

    if (event && event.data && event.data.payload.event.uri) {
      if (oneTimeAPICallForUser) {
        setTimeout(() => {
          setOneTimeAPICallForUser(false);

          Axios.get(event.data.payload.invitee.uri, { headers })
            .then((res) => {
              // user API CALL

              emailjs
                .send("termnltech", "template_gb7fdak", {
                  name: res.data.resource.name,
                  reply_to: res.data.resource.email,
                })
                .then((res) => {})
                .catch((err) => {});
              

              // ADMIN API CALL

              emailjs
                .send("termnltech", "template_qcdkvkb", {
                  name: res.data.resource.name,
                  email: res.data.resource.email,
                  guestEmail: guest,
                  meeting:
                    res.data.resource &&
                    res.data.resource.questions_and_answers.length
                      ? res.data.resource.questions_and_answers[0].answer
                      : "",
                  project: answers[1]?.answer,
                  hope: answers[2]?.answer,
                  users: answers[3]?.answer,
                  date: answers[4]?.answer,
                  budget: answers[0]?.answer,
                  purpose:answers[5]?.answer[0].answer,
                  problem:answers[5]?.answer[1].answer,
                  audience:answers[5]?.answer[2].answer,
                  additional_details:answers[5]?.answer[3].answer,
                  meeting_time: meeting,
                  to_email: "termnltech@gmail.com",
                })
                .then((res) => {
                  setLoader(false);
                })
                .catch((err) => {
                  setLoader(false);
                });
            })
            .catch((err) => {
              setLoader(false);
            });
        }, 3000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const value = {
    handleSubmit,
    sidebarCollapsed,
    setSidebarCollapsed,
    questionNumber,
    answers,
    formComplete,
    decreaseQuestion,
    setModalShow,
    modalShow,
    setEvent,
    setQuestionNumber,
    Loader,
  };

  return (
    <QuestionnaireProviderContext.Provider value={value}>
      {children}
    </QuestionnaireProviderContext.Provider>
  );
}
