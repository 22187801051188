/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";
import mainLogo from "../assets/svg/phone_image.webp";
import termnlLogo from "../assets/svg/termnl_logo.webp";

const Header = () => {
  const location = useLocation();
  const showHeader = location.pathname === "/";

  if (!showHeader) {
    return null;
  }
  return (
    <header className="header-wrap header-1 header-4">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div className="logo">
          <a href="#">
            <img
              src={termnlLogo}
              width={"208"}
              height={"29.45"}
              style={{
                width: "13em",
              }}
              alt="logo"
            />
          </a>
        </div>
        <div className="header-right-area d-flex">
          <div className="main-menu d-none me-0 pe-xl-5 me-xl-5 d-xl-block">
            <ul>
              <li>
                {/*<a href="home">*/}
                {/*  Home*/}
                {/*</a>*/}
                {/*<ul className="sub-menu">*/}
                {/*  <li>*/}
                {/*    <a href="index.html">homepage 1</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="index-2.html">homepage 2</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="index-3.html">homepage 3</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="index-4.html">homepage 4</a>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#projects">portfolio</a>
              </li>
              <li>
                <a href="/pricing">pricing</a>
              </li>
              <li>
                <a href="/portal">portal</a>
              </li>
              <li>
                <div className="call-us">
                  <img
                    className="phone-image"
                    style={{
                      width: "1.5em",
                      marginRight: "0.7em",
                    }}
                    src={mainLogo}
                    alt="fireSpot"
                  />
                  Call Us +1 (855) 463 - 5786
                </div>
              </li>
              <li>
                {/*<a href="#">*/}
                {/*  Pages <i className="fal fa-plus"></i>*/}
                {/*</a>*/}
                {/*<ul className="sub-menu">*/}
                {/*  <li>*/}
                {/*    <a href="about-2.html">about 2</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="team.html">team</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="faq.html">faq</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="projects.html">projects</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="projects-2.html">projects 2</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="pricing.html">Pricing</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="pricing-2.html">Pricing 2</a>*/}
                {/*  </li>*/}
                {/*  <li>*/}
                {/*    <a href="services-2.html">services 2</a>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              </li>
              {/*<li>*/}
              {/*  <a href="news.html">News</a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a href="contact.html">Contact</a>*/}
              {/*</li>*/}
              <li>
                {/*<a href="#" className="search-btn">*/}
                {/*  <i className="fas fa-search"></i>*/}
                {/*</a>*/}
                <div className="search-box">
                  <form action="#">
                    <input type="text" placeholder="Search" />
                    <button type="submit" className="app-btn-color">
                      <i className="fal fa-search"></i>
                    </button>
                  </form>
                </div>
              </li>
            </ul>
          </div>
          <div className="header-right-elements d-flex align-items-center justify-content-between">
            <div></div>
            {/*<div*/}
            {/*  className="call-us"*/}
            {/*>*/}
            {/*  <img className="phone-image" style={{*/}
            {/*    width: "1.5em",*/}
            {/*    marginRight: "1.5em"*/}
            {/*  }} src={mainLogo} alt="fireSpot"/>*/}
            {/*  Call Us +1 (855) 463 - 5786*/}
            {/*</div>*/}
            <div className="d-inline-block ms-4 d-xl-none">
              <div className="mobile-nav-wrap">
                <div id="hamburger" className="text-white">
                  <i className="fal fa-bars"></i>
                </div>
                {/* mobile menu - responsive menu   */}
                <div className="mobile-nav">
                  <button
                    type="button"
                    className="close-nav app-btn-color"
                    aria-label="Aria Name"
                  >
                    <i className="fal fa-times-circle"></i>
                  </button>
                  <nav className="sidebar-nav">
                    <ul className="metismenu" id="mobile-menu">
                      {/*<li>*/}
                      {/*  <a className="has-arrow" href="#">*/}
                      {/*    Homes*/}
                      {/*  </a>*/}
                      {/*  <ul className="sub-menu">*/}
                      {/*    <li>*/}
                      {/*      <a href="index.html">homepage 1</a>*/}
                      {/*    </li>*/}
                      {/*    <li>*/}
                      {/*      <a href="index-2.html">homepage 2</a>*/}
                      {/*    </li>*/}
                      {/*    <li>*/}
                      {/*      <a href="index-3.html">homepage 3</a>*/}
                      {/*    </li>*/}
                      {/*    <li>*/}
                      {/*      <a href="index-4.html">homepage 4</a>*/}
                      {/*    </li>*/}
                      {/*  </ul>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <a href="services.html">Services</a>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <a href="projects.html">portfolio</a>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <a className="has-arrow" href="#">*/}
                      {/*    Pages*/}
                      {/*  </a>*/}
                      {/*  <ul className="sub-menu">*/}
                      {/*    <li>*/}
                      {/*      <a href="faq.html">faq</a>*/}
                      {/*    </li>*/}
                      {/*    <li>*/}
                      {/*      <a href="services-details.html">services details</a>*/}
                      {/*    </li>*/}
                      {/*    <li>*/}
                      {/*      <a href="team.html">Team</a>*/}
                      {/*    </li>*/}
                      {/*  </ul>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <a href="news.html">News</a>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <a href="contact.html">Contact</a>*/}
                      {/*</li>*/}
                    </ul>
                  </nav>

                  <div className="action-bar">
                    <li>
                      <a href="/#services">Services</a>
                    </li>
                    <li>
                      <a href="/#projects">portfolio</a>
                    </li>
                    <li>
                      <a href="/pricing">pricing</a>
                    </li>
                    <li>
                      <a href="/portal">portal</a>
                    </li>
                    <a href="mailto:modinatheme@gmail.com">
                      <i className="fal fa-envelope"></i>info@termnl.tech
                    </a>
                    <a href="tel:123-456-7890">
                      <i className="fal fa-phone"></i>+1 (855) 463 - 5786
                    </a>
                    {/*<a href="contact.html" className="d-btn theme-btn">*/}
                    {/*  Contact Us*/}
                    {/*</a>*/}
                  </div>
                </div>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
