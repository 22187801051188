import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import emailjs, { init } from "emailjs-com";
import { Form, Field } from "react-final-form";
import { toast } from "react-toastify";
init("yWi2kgDl-TMKQjC09");
const Footer = ({selectedTitle}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values) => {
    setIsSubmitting(true);
    emailjs
      .send("termnltech", "template_gb7fdak", {
        name: values.name,
        email: values.email,
        number: values.number,
        subject: values.subject,
        message: values.message,
      })
      .then(
        (res) => {
          toast.success("You message is successfully submitted", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        },
        (err) => {
          toast.error("You message is not submitted", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const location = useLocation();
  const showFooter = location.pathname==="/" || location.pathname==="/pricing" ;


  if (!showFooter) {
    return null;
  }

  return (
    <footer className="footer-4 footer-wrap" id="contact-submit">
      <div>
        <div className="contact-us-wrapper section-padding">
          <div className="container">
            <div className="row eq-height">
              <div className="col-lg-8 col-12">
                <div id="contactForm" className="contact-form">
                  <h2
                    style={{
                      color: "black",
                    }}
                  >
                    Free Quote
                  </h2>
                  <Form
                    onSubmit={onSubmit}
                    render={({ handleSubmit, form, values, submitting }) => (
                      <form onSubmit={handleSubmit} className="row" data-testid="contact-form">
                        <div className="col-md-6 col-12">
                          <div className="single-personal-info">
                            <Field
                              type="text"
                              name="name"
                              placeholder="Name"
                              component={"input"}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="single-personal-info">
                            <Field
                              type="email"
                              name="email"
                              placeholder="Email"
                              component="input"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="single-personal-info">
                            <Field
                              type="text"
                              name="number"
                              placeholder="Number"
                              component={"input"}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="single-personal-info">
                            <Field
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              component={"input"}
                              required
                              initialValue={selectedTitle}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="single-personal-info">
                            <Field
                              name="message"
                              placeholder="message"
                              component={"textarea"}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <button
                            disabled={isSubmitting}
                            className="submit-btn"
                            type="submit"
                          >
                            {isSubmitting && (
                              <span className="spinner-grow spinner-grow-sm"></span>
                            )}
                            &nbsp;
                            {isSubmitting ? " Submitting" : "Submit Now"}
                          </button>
                        </div>
                      </form>
                    )}
                  />

                  <span className="form-message"></span>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="contact-us-sidebar mt-5 mt-lg-0">
                  <div className="contact-info">
                    <h2
                      style={{
                        color: "black",
                      }}
                    >
                      CONTACT INFO
                    </h2>
                    <div className="single-info">
                      {/*<div className="icon">*/}
                      {/*    <i className="flaticon-email"></i>*/}
                      {/*</div>*/}
                      <div className="text">
                        <span>Email Us</span>
                        <h5
                          style={{
                            color: "black",
                          }}
                        >
                          info@termnl.tech
                        </h5>
                      </div>
                    </div>
                    <div className="single-info">
                      <div className="text">
                        <span>Call Us</span>
                        <h5
                          style={{
                            color: "black",
                          }}
                        >
                          +1 (855) 463- 5786
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
