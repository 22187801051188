import React from "react";
import { useLocation } from "react-router-dom";
import coworkersImage from "components/assets/svg/worker3.webp";

const WeWork = () => {
  const location = useLocation();
  const showWeWork = location.pathname === "/";

  if (!showWeWork) {
    return null;
  }
  return (
    <section className="we-work-skill-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 mb-4 offset-xl-3 offset-lg-2 col-12 text-lg-center">
            <div className="section-title-4">
              <h2>We Build Reliable Technology Using Industry Standards</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 col-xl-6 mt-lg-4">
            <div className="our-achievement-skill-wrapper">
              <ul>
                <li>Skills</li>
                <li>Experience</li>
                <li>Creativity</li>
              </ul>

              <p className="border-left">
                Product development is our niche. The company’s agile,
                comprehensive, and future-proof product engineering services
                provide support for both fresh startups, from the initial idea
                to post-delivery upgrades, and mature companies in need of
                product development.
              </p>

              <div className="skill-circle-progress-wrapper row">
                <div className="col-sm-6">
                  <div className="single-skill-circle-bar mt-30 d-flex align-items-center">
                    <div
                      className="mkCharts"
                      data-color="#72DE9B"
                      data-percent="90"
                      data-size="125"
                      data-stroke="1"
                    ></div>
                    <div className="contents ms-3">
                      <h6>Web App Code Coverage Target</h6>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="single-skill-circle-bar mt-30 d-flex align-items-center">
                    <div
                      className="mkCharts"
                      data-color="#72DE9B"
                      data-percent="85"
                      data-size="125"
                      data-stroke="1"
                    ></div>
                    <div className="contents ms-3">
                      <h6>Mobile App Code Coverage Target</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 mt-5 col-md-5 offset-xl-1 mt-lg-4">
            <div className="brand-client-img">
              <img src={coworkersImage} alt="" loading="lazy" />
              <div className="company-year">
                <p>Since</p>
                <h2>2012</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeWork;
