/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useQuestionnaireProvider } from "context/QuestionnaireProvider";
import termnlHeader from "components/assets/images/termnl_logo.svg";
import avatarImg from "components/assets/images/avatar.png";
import { useCookies } from "react-cookie";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Home.scss";
import AuthContext from "context/auth-context";

function Header() {
  const { setQuestionNumber } = useQuestionnaireProvider();
  const [cookies] = useCookies(["user"]);
  // const auth = useAuth();
  const authCtx = useContext(AuthContext);

  return (
    <>
      <div className="navbar-style" style={{ zIndex: "3" }}>
        <Navbar expand="md" className="w-100 h-100" bg="dark" variant="dark">
          <div className="d-flex align-items-center w-100 justify-content-between mw-100 flex-wrap">
            <div className="logo cursor-pointer">
              <h1 className="fw-bold d-flex align-items-center">
                <a
                  href="/"
                  onClick={() => {
                    setQuestionNumber(0);
                  }}
                >
                  <img
                    style={{ transform: "translate(0px, 0.3em)" }}
                    src={termnlHeader}
                    width="120em"
                    height="40em"
                    alt="termnlHeader"
                  />
                </a>
              </h1>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end flex-wrap"
            >
              {!authCtx.isLoggedIn ? (
                <ul className="navbar-auth navbar-nav">
                  <li className="navbar-item">
                    <Link to="/login" className="link-item signin-btn">
                      Sign in
                    </Link>
                  </li>
                  <li className="navbar-item">
                    <Link to="/register" className="link-item  signup-btn">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-items">
                  <li className="navbar-item">
                    <Link to="/appointment" className="link-item">
                      Book Appointment
                    </Link>
                  </li>
                  <li className="navbar-item">
                    <Link to="/newProject" className="link-item">
                      New Project
                    </Link>
                  </li>
                  <li className="navbar-item">
                    <Link to="/about" className="link-item">
                      About
                    </Link>
                  </li>
                  <li className="navbar-item">
                    <Link to="/payment" className="link-item">
                      Pay
                    </Link>
                  </li>
                  {/* <li className="navbar-item dropdown">
                    <a href="#" className="link-item dropbtn">
                      App
                    </a>
                    <div className="dropdown-content">
                      <Link to="/documents">Documents</Link>
                      {cookies.user?.role !== "CLIENT" && (
                        <Link to="/projects-view">Projects</Link>
                      )}

                      <Link to="/history">Progress</Link>
                    </div>
                  </li> */}
                  <Nav className="navbar-item dropdown">
                    <a href="#" className="link-item">
                      <span>{cookies.user?.first_name}</span>
                      <img
                        src={avatarImg}
                        className="avatar-img"
                        alt="Avatar"
                      />
                    </a>
                    <div className="dropdown-content">
                      <a
                        href="/portal"
                        className="navbar-item"
                        onClick={() => authCtx.onLogout()}
                      >
                        logout
                      </a>
                    </div>
                  </Nav>
                </ul>
              )}
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
