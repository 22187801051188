import React from "react";
import { useLocation } from "react-router-dom";
import code from "components/assets/svg/code.webp";

const FaqAsk = () => {
  const location = useLocation();
  const showFaqAsk = location.pathname === "/";

  if (!showFaqAsk) {
    return null;
  }
  return (
    <section className="faq-ask-wrapper section-padding pt-0">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-12">
            <div className="call-phone-cta">
              <div className="icon">
                <i className="icon-call"></i>
              </div>
              <div className="content">
                <span>Call for support</span>
                <h3>+1 (855) 463 - 5786</h3>
              </div>
            </div>
            <div className="faq-img mt-20">
              <img src={code} alt="" loading="lazy" />
            </div>
          </div>
          <div className="col-xl-7 col-12 mt-5 mt-xl-0">
            <div className="faq-question-wrapper">
              <h2>Frequently Asked Questions</h2>
              <div className="faq-content">
                <div className="faq-ask-list mtm-20">
                  <div className="accordion" id="accordion">
                    <div className="accordion-item">
                      <h4 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq1"
                          aria-expanded="false"
                          aria-controls="faq1"
                        >
                          How feasible is my idea?
                        </button>
                      </h4>
                      <div
                        id="faq1"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="accordion-body">
                          The only way to get an answer to this question is to
                          test the idea in the field of real prospects. We will
                          start by transcribing your vision to create a working
                          prototype and then open it to the public to gain
                          analytics and measure interest. We will then compare
                          your idea to competitors to see how similar apps have
                          performed in the market to know if yours would
                          succeed.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq2"
                          aria-expanded="true"
                          aria-controls="faq2"
                        >
                          Are there similar apps in the market?
                        </button>
                      </h4>
                      <div
                        id="faq2"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordion"
                      >
                        <div className="accordion-body">
                          Most likely, there is. Competition is essential not
                          just in the app development process but also in
                          deciding an application's future. The first step in
                          the competitive analysis is to know if similar apps
                          are in the app stores like yours. Gaining this
                          information will help you get answers to not just the
                          questions related to what all your app should have but
                          will also give you insights into what is working for
                          your competitors and what isn't.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq3"
                          aria-expanded="false"
                          aria-controls="faq3"
                        >
                          How long does it take to build an app?
                        </button>
                      </h4>
                      <div
                        id="faq3"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="accordion-body">
                          Depending on the complexity of the application, most
                          apps take months to complete. From the app development
                          process straight from ideation to launch, some
                          technical apps can take up to a year or even more. You
                          don’t always have to wait that long for your
                          development process to end. Here are the ways you can
                          speed up your app development timeline. First, measure
                          this time against the time you have decided to launch
                          your app, and second, determine if you want to create
                          an MVP first or wait until the whole app is
                          full-fledged and developed before launch.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h4 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq4"
                          aria-expanded="false"
                          aria-controls="faq4"
                        >
                          What is the cost of developing an app?
                        </button>
                      </h4>
                      <div
                        id="faq4"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordion"
                      >
                        <div className="accordion-body">
                          Like time, the cost is another factor that depends
                          entirely on the number of features you plan to have in
                          your mobile app. Getting a quote on the price range
                          from different app development agencies will help you
                          form and fix a budget. The project's total cost
                          includes designing, coding, development, testing, and
                          app integration with existing systems. Feel free to
                          send us a message; we'd be happy to assist with
                          creating a budget for you.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqAsk;
