import React, { useState, useMemo } from "react";
import { useCookies } from "react-cookie";

const AuthContext = React.createContext({
  isLoggedIn: false,
  authToken: null,
  onLogout: () => {},
  onLogin: (email, password) => {},
  cookies: {},
  firstName: "",
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [firstName, setFirstName] = useState("");

  useMemo(() => {
    if (cookies.user) {
      setIsLoggedIn(true);
      setAuthToken(cookies.user.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutHandler = () => {
    removeCookie("user");
    setIsLoggedIn(false);
  };

  const loginHandler = async (data) => {
    const url = `${process.env.REACT_APP_API}/api/login`;
    const req = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const res = await req.json();
    if (res.error === 1) {
      return { error: res.data };
    } else {
      setIsLoggedIn(true);
      setFirstName(res.data.first_name);
      setCookie("user", res.data, { path: "/" });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        onLogout: logoutHandler,
        authToken: authToken,
        onLogin: loginHandler,
        cookies: cookies,
        firstName: firstName,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
