import React from "react";
import { useLocation } from "react-router-dom";
import Background from "components/assets/svg/coworkers1.webp";

const Header = () => {
  const location = useLocation();
  const showHeader = location.pathname === "/";

  if (!showHeader) {
    return null;
  }
  return (
    <div className="our-agency-about-wrapper section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6">
            <div className="agency-img">
              <img src={Background} alt="" loading="lazy" />
            </div>
          </div>
          <div className="col-xl-6 ps-xl-5 mt-5 mt-xl-0">
            <div className="about-agency-content">
              <div className="section-title-4">
                <h2>Developing Solutions For Businesses</h2>
                <p>
                  We believe in developing useful software instead of just
                  fulfilling initial requirements. During the past 10 years,
                  with an agile mindset, we have delivered 500+ different
                  projects, ranging from startups to enterprises.
                </p>
              </div>
              <div className="checked-features-list theme-color-4">
                <ul>
                  <li>Modern Technology</li>
                  <li>Reliable Software</li>
                  <li>Quality Testing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
