import React from "react";
import Background from "components/assets/svg/chicagoBuildings.webp";
import Background2 from "components/assets/svg/sky_line.webp";

const AgencyHero = () => {
  // Function to check if the browser is Chrome or Firefox
  const isChromeOrFirefox = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log("User Agent:", userAgent); // Log the userAgent to the console
    return userAgent.includes("chrome") || userAgent.includes("firefox");
  };

  return (
    <section className="agency-hero-wrapper hero-wrapper fix">
      <div className="agency-slider-active owl-theme owl-carousel">
        {isChromeOrFirefox() ? (
          // Show the original content with animations for Chrome and Firefox
          <>
            <div
              className="single-agency-slide bg-cover"
              style={{ backgroundImage: `url('${Background}')` }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-md-10 col-12">
                    <div className="hero-contents">
                      <h1 className="animated-text bg-heading text-white animated">
                        Your Gateway to Exceptional Web and Mobile Development
                      </h1>
                      <a href="#contactForm" className="submit-inquiry">
                        Get a Free Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="single-agency-slide bg-cover"
              style={{ backgroundImage: `url('${Background2}')` }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-md-10 col-12">
                    <div className="hero-contents">
                      <h1
                        className="animated-text bg-heading text-white animated"
                        style={{ marginBottom: "0.5em" }}
                      >
                        Digital Web Agency
                      </h1>
                      <a href="#contactForm" className="submit-inquiry">
                        Get a Free Quote
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          // Show only the container without animations for other browsers
          <div
            className="single-agency-slide bg-cover"
            style={{ backgroundImage: `url('${Background}')` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-md-10 col-12">
                  <div className="hero-contents">
                    <h1 className="bg-heading text-white">
                      Your Gateway to Exceptional Web and Mobile Development
                    </h1>
                    <a href="#contactForm" className="submit-inquiry">
                      Get a Free Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default AgencyHero;
