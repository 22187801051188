import React, { createContext, useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AuthContext from "context/auth-context";

const ProjectsDataContext = createContext({ projectsData: [] });

export const ProjectsDataProvider = (props) => {
  const [projectsData, setProjectsData] = useState([]);
  const authCtx = useContext(AuthContext);
  const user = authCtx.cookies.user;

  const getProjectIds = async () => {
    const projectData = await fetch(
      `${process.env.REACT_APP_API}/project/metadata`,
      {
        mode: 'no-cors',
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
      }
    )
      .then((res) => res.json())
      .then((files) => files[0])
      .catch((err) => {});

    return projectData;
  };

  useEffect(() => {
    if (!user) return;

    (async () => {
      const results = await getProjectIds();
      const curUserRole = user?.role;

      if (curUserRole === "ADMIN") {
        setProjectsData(results);
      } else {
        const project = results?.filter((result) => {
          if (curUserRole === "CLIENT") {
            return Number(result.client_id) === Number(user.id) ? true : false;
          } else if (curUserRole === "DEVELOPER") {
            return JSON.parse(result.developer_id).includes(user.id)
              ? true
              : false;
          }
          return false;
        });

        setProjectsData(project);
      }
    })();
  }, [user]);

  return (
    <ProjectsDataContext.Provider value={{ projectsData: projectsData }}>
      {props.children}
    </ProjectsDataContext.Provider>
  );
};

export default ProjectsDataContext;
