/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";
import recentProjectPNC from "components/assets/svg/pnc.webp";
import honestCompany from "components/assets/svg/theHonestCompanyUpdated.webp";
import Joann from "components/assets/svg/joannUpdated.webp";
import jdSports from "components/assets/svg/jdSportsUpdated.webp";
import fordMotorCompany from "components/assets/svg/fordUpdated.webp";
import depaulUniversity from "components/assets/svg/depaulUpdated.webp";
import cvs from "components/assets/svg/cvshealthUpdated.webp";

const Portfolio = () => {
  const location = useLocation();
  const showPortfolio = location.pathname === "/";

  if (!showPortfolio) {
    return null;
  }
  return (
    <section
      className="portfolio-showcase-carosuel-wrapper fix section-padding pb-0"
      id="projects"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 mb-4 offset-xl-3 offset-lg-2 col-12 text-center">
            <div className="section-title-4">
              <h2>We're happy to share our recent projects</h2>
            </div>
          </div>
        </div>
      </div>
      <div
        className="portfolio-showcase-carousel-active owl-carousel"
        style={{ paddingBottom: "8em" }}
      >
        <div
          className="single-recent-portfolio bg-cover"
          style={{ backgroundImage: `url('${recentProjectPNC}')` }}
        >
          <div className="project-details">
            <h5>
              <a href="#" className="pe-none" style={{ color: "inherit" }}>
                Home Mortgages
              </a>
            </h5>
            <span>Web Development</span>
            {/* <a href="#" className="project-link">
              <i className="fal fa-chevron-right"></i>
            </a> */}
          </div>
        </div>
        <div
          className="single-recent-portfolio bg-cover"
          style={{ backgroundImage: `url('${honestCompany}')` }}
        >
          <div className="project-details">
            <h5>
              <a href="#" className="pe-none" style={{ color: "inherit" }}>
                IPO Launch
              </a>
            </h5>
            <span>Consulting</span>
            {/* <a href="#" className="project-link">
              <i className="fal fa-chevron-right"></i>
            </a> */}
          </div>
        </div>
        <div
          className="single-recent-portfolio bg-cover"
          style={{ backgroundImage: `url('${Joann}')` }}
        >
          <div className="project-details">
            <h5>
              <a href="#" className="pe-none" style={{ color: "inherit" }}>
                IPO Launch
              </a>
            </h5>
            <span>Consulting</span>
            {/* <a href="#" className="project-link">
              <i className="fal fa-chevron-right"></i>
            </a> */}
          </div>
        </div>
        <div
          className="single-recent-portfolio bg-cover"
          style={{ backgroundImage: `url('${jdSports}')` }}
        >
          <div className="project-details">
            <h5>
              <a href="#" className="pe-none" style={{ color: "inherit" }}>
                Digital Platforms Implementation & Refactor
              </a>
            </h5>
            <span>Web & Mobile Development</span>
            {/* <a href="#" className="project-link">
              <i className="fal fa-chevron-right"></i>
            </a> */}
          </div>
        </div>
        <div
          className="single-recent-portfolio bg-cover"
          style={{ backgroundImage: `url('${fordMotorCompany}')` }}
        >
          <div className="project-details">
            <h5>
              <a href="#" className="pe-none" style={{ color: "inherit" }}>
                Self-Driving Autonomous Vehicle Software
              </a>
            </h5>
            <span>Web Development</span>
            {/* <a href="#" className="project-link">
              <i className="fal fa-chevron-right"></i>
            </a> */}
          </div>
        </div>
        <div
          className="single-recent-portfolio bg-cover"
          style={{ backgroundImage: `url('${depaulUniversity}')` }}
        >
          <div className="project-details">
            <h5>
              <a href="#" className="pe-none" style={{ color: "inherit" }}>
                Distributed Systems & Database Management
              </a>
            </h5>
            <span>Web & Mobile Development</span>
            {/* <a href="#" className="project-link">
              <i className="fal fa-chevron-right"></i>
            </a> */}
          </div>
        </div>
        <div
          className="single-recent-portfolio bg-cover"
          style={{ backgroundImage: `url('${cvs}')` }}
        >
          <div className="project-details">
            <h5>
              <a href="#" className="pe-none" style={{ color: "inherit" }}>
                Internal Client Health Care Applications
              </a>
            </h5>
            <span>Consulting & Web Development</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
