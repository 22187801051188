// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scroll-content-spacing {
  border-radius: 0.5em;
  width: 100%;
}

p {
  font-size: 0.87em !important;
  /*width: 75%;*/
}

.list-items {
  font-size: 0.8em;
  font-weight: bold;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #161616;
}

*::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #2b2e32;
  border-radius: 50px;
  border-right: 1px solid #161616;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* For Firefox  */

input[type="number"] {
  -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;AACA,iBAAiB;;AAEjB;EACE,0BAA0B;AAC5B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.scroll-content-spacing {\n  border-radius: 0.5em;\n  width: 100%;\n}\n\np {\n  font-size: 0.87em !important;\n  /*width: 75%;*/\n}\n\n.list-items {\n  font-size: 0.8em;\n  font-weight: bold;\n}\n\n*::-webkit-scrollbar {\n  width: 8px;\n  background-color: #161616;\n}\n\n*::-webkit-scrollbar-thumb {\n  width: 6px;\n  background-color: #2b2e32;\n  border-radius: 50px;\n  border-right: 1px solid #161616;\n}\n\ninput[type=\"number\"]::-webkit-outer-spin-button,\ninput[type=\"number\"]::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n/* For Firefox  */\n\ninput[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
