/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation } from "react-router-dom";
const OurService = () => {
  const location = useLocation();
  const showOurService = location.pathname === "/";

  if (!showOurService) {
    return null;
  }
  return (
    <section
      className="our-service-wrapper bg-black section-padding"
      id="services"
    >
      <div className="container">
        <div className="row mtm-30">
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="block-contents text-white">
              <div className="section-title-4">
                <h2>Services We Offer</h2>
                <p>
                  We are a team of passionate developers, designers, project
                  managers, and marketers who aim to bring innovative software
                  to the market efficiently and at a competitive price.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="service-item-card">
              <div className="service-number">01</div>
              <div className="icon">
                <i className="icon-coding"></i>
              </div>
              <div className="contents">
                <h3>
                  <a href="#" className="pe-none">
                    Mobile & Web Development
                  </a>
                </h3>
                <p>
                  Cross-platform, native, Android, and iOS development.
                  Frontend, backend, SaaS cloud-based development, and much more
                  for your mobile and web needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="service-item-card">
              <div className="service-number">02</div>
              <div className="icon">
                <i className="icon-bullhorn"></i>
              </div>
              <div className="contents">
                <h3>
                  <a href="#" className="pe-none">
                    Digital Marketing
                  </a>
                </h3>
                <p>
                  Facebook and Google advertising campaigns. Grow your awareness
                  and conversion through paid promotion and dynamic ads.
                  Implement custom audience and retargeting to increase traffic
                  and installs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="service-item-card">
              <div className="service-number">03</div>
              <div className="icon">
                <i className="icon-writing"></i>
              </div>
              <div className="contents">
                <h3>
                  <a href="#" className="pe-none">
                    Technical Copy Writing
                  </a>
                </h3>
                <p>
                  Professional copyright for your mobile and web applications.
                  Privacy statements, legal documents, product descriptions, and
                  more.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="service-item-card">
              <div className="service-number">04</div>
              <div className="icon">
                <i className="icon-curve"></i>
              </div>
              <div className="contents">
                <h3>
                  <a href="#" className="pe-none">
                    UI/UX Design
                  </a>
                </h3>
                <p>
                  Transform your idea into a high-quality visual design. Develop
                  a prototype of the UI to test and explore your product with
                  stakeholders.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="service-item-card">
              <div className="service-number">05</div>
              <div className="icon">
                <i className="icon-brainstorming"></i>
              </div>
              <div className="contents">
                <h3>
                  <a href="#" className="pe-none">
                    Consultancy
                  </a>
                </h3>
                <p>
                  Analyze your company's software needs and discover what
                  technologies are needed to help perform your operations
                  successfully.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurService;
