import { useLocation } from "react-router-dom";

const FunFact = () => {
  const location = useLocation();
  const showFunFact = location.pathname === "/";

  if (!showFunFact) {
    return null;
  }
  return (
    <section
      className="fun-fact-banner bg-overlay text-white bg-cover"
      style={{ backgroundImage: `url('assets/img/home4/funfact-bg.jpg')"` }}
    >
      <div className="container">
        <div className="row text-center text-md-start">
          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-fun-fact mb-30">
              <h2>
                <span>580</span>
              </h2>
              <h4>PROJECT DONE</h4>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-fun-fact mb-30">
              <h2>
                <span>2</span>K
              </h2>
              <h4>Happy Customers</h4>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-fun-fact mb-30">
              <h2>
                <span>12</span>+
              </h2>
              <h4>Awards Achieved</h4>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <div className="single-fun-fact mb-30">
              <h2>
                <span>19</span>+
              </h2>
              <h4>Technical Experts</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
