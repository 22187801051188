import React, { useEffect, useState } from "react";
import AgencyAbout from "./AgencyAbout";
import AgencyHero from "./AgencyHero";
import FaqAsk from "./FaqAsk";
import Footer from "./Footer";
import FunFact from "./FunFact";
import Header from "components/Marketing/Header";
import OurService from "./OurService";
import Portfolio from "./Portfolio";
import PromoBanner from "./PromoBanner";
import WeWork from "./WeWork";
import "components/assets/library/css/icons.css";
import "components/assets/library/css/animate.css";
import "components/assets/library/css/magnific-popup.css";
import "components/assets/library/css/mk_charts.css";
import "components/assets/library/css/owl.carousel.min.css";
import "components/assets/library/css/metismenu.css";
import "components/assets/library/css/owl.theme.css";
import "components/assets/library/css/style.css";

const MarketingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatchEvent(new Event("load"));
    const timeoutId = setTimeout(() => setIsLoaded(true), 5);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const sequentialElements = [
    { component: AgencyHero, key: "agencyHero" },
    { component: AgencyAbout, key: "agencyAbout" },
    { component: OurService, key: "ourService" },
    { component: WeWork, key: "weWork" },
    { component: Portfolio, key: "portfolio" },
    { component: FunFact, key: "funFact" },
    { component: FaqAsk, key: "faqAsk" },
    { component: Header, key: "header" },
    { component: PromoBanner, key: "promoBanner" },
    { component: Footer, key: "footer" },
  ];

  return (
    <>
      {!isLoaded}
      {sequentialElements.map(({ component: Component, key }, index) => (
        <div
          key={key}
          style={{
            transition: "opacity 3ms ease-in-out",
            opacity: isLoaded ? 1 : 0,
            visibility: isLoaded ? "visible" : "hidden",
          }}
        >
          <Component />
        </div>
      ))}
    </>
  );
};

export default MarketingPage;
