import React from "react";

const DataDeletion = () => {
  return (
    <div style={{paddingLeft:20, paddingRight:20, marginTop:10, marginBottom:10}}>
      <h1>Data Deletion Instructions for MRKT Ads</h1>
      <h6>Last updated: February 18th, 2025</h6>
      <h3>How to Request Data Deletion</h3>
      
      <ol>
      <li>If you would like to delete your data from our platform, please follow these steps:</li>
      <li><strong>Ⅰ. </strong>Send an email to <a href="mailto:info@termnl.tech">info@termnl.tech</a> with the subject line "Data Deletion Request."</li>
      <li><strong>Ⅱ. </strong>Include the following information in your email:
          <ul>
            <li><strong>• </strong>Your full name.</li>
            <li><strong>• </strong>The email address associated with your account.</li>
            <li><strong>• </strong>A brief description of your request.</li>
          </ul>
        </li>
      </ol>
      <h3>What Happens Next?</h3>
      <ul>
      <li><strong>Ⅰ. </strong>Verify your identity to ensure the request is legitimate.</li>
        <li><strong>Ⅱ. </strong>Delete your data from our systems within 30 days.</li>
        <li><strong>Ⅲ. </strong>Notify you once the process is complete.</li>
      </ul>
      <h3>Contact Us</h3>
      <p>If you have any questions about data deletion, please contact us at <a href="mailto:info@termnl.tech">info@termnl.tech</a>.</p>
    </div>
  );
};

const TermsOfService = () => {
  return (
    <div style={{paddingLeft:20, paddingRight:20, marginTop:10, marginBottom:10}}>
      <h1>MRKT Ads Terms of Service</h1>
      <h6>Last updated: February 18th, 2025</h6>
      <h3>1. Introduction</h3>
      <p>Welcome to MRKT Ads ("we," "our," "us"). By using our platform, you agree to these Terms of Service. Please read them carefully before using our services.</p>
      <h3>2. Our Services</h3>
      <p>We are a marketing platform that helps users market on platforms such as Facebook, Instagram, and TikTok. Our services include:</p>
      <ul>
        <li><strong>Ⅰ. </strong>Account management for advertising platforms.</li>
        <li><strong>Ⅱ. </strong>Campaign creation and optimization.</li>
        <li><strong>Ⅲ. </strong>Performance analytics and reporting.</li>
      </ul>
      <h3>3. User Responsibilities</h3>
      <p>By using our platform, you agree to:</p>
      <ul>
      <li><strong>Ⅰ. </strong>Provide accurate and complete information during account registration.</li>
        <li><strong>Ⅱ. </strong>Comply with all applicable laws and regulations.</li>
        <li><strong>Ⅲ. </strong>Not use our platform for illegal or unauthorized purposes.</li>
      </ul>
      <h3>4. Intellectual Property</h3>
      <p>All content and materials on our platform, including logos, designs, and software, are the property of [Your App Name] and are protected by intellectual property laws.</p>
      <h3>5. Limitation of Liability</h3>
      <p>We are not liable for any indirect, incidental, or consequential damages arising from your use of our platform.</p>
      <h3>6. Changes to These Terms</h3>
      <p>We may update these Terms of Service from time to time. Any changes will be posted on this page, and we will notify you of significant updates.</p>
      <h3>7. Contact Us</h3>
      <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:info@termnl.tech">info@termnl.tech</a>.</p>
    </div>
  );
};

const PrivacyPolicy = () => {
  return (
    <div style={{paddingLeft:20, paddingRight:20, marginTop:10, marginBottom:10}}>
      <h1>MRKT Ads Privacy Policy</h1>
      <h6>Last updated: February 18th, 2025</h6>
      <h3>1. Introduction</h3>
      <p>Welcome to MRKT Ads ("we," "our," "us"). We are committed to protecting your privacy and ensuring that your personal information is handled responsibly.</p>
      <h3>2. Information We Collect</h3>
      <p>We collect the following information to help with account login and provide our services:</p>
      <ul>
        <li><strong>Email Address</strong>: Used for account creation and communication.</li>
        <li><strong>Phone Number</strong>: Used for account verification and security.</li>
        <li><strong>First Name and Last Name</strong>: Used to personalize your experience.</li>
      </ul>
      <h3>3. How We Use Your Information</h3>
      <ul>
        <li><strong>Ⅰ. </strong>Create and manage your account.</li>
        <li><strong>Ⅱ. </strong>Provide customer support.</li>
        <li><strong>Ⅲ. </strong>Improve our platform and services.</li>
        <li><strong>Ⅳ. </strong>Communicate with you about updates and promotions.</li>
      </ul>
      <h3>4. Data Security</h3>
      <p>We take data security seriously and implement industry-standard measures to protect your information from unauthorized access, alteration, or disclosure.</p>
      <h3>5. Third-Party Services</h3>
      <p>We may use third-party services (e.g., Facebook, Instagram, TikTok) to provide our services. These services have their own privacy policies, and we encourage you to review them.</p>
      <h3>6. Changes to This Policy</h3>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant updates.</p>
      <h3>7. Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@termnl.tech">info@termnl.tech</a>.</p>
    </div>
  );
};

export { DataDeletion, TermsOfService, PrivacyPolicy };
