import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/components/assets/css/common.min.css";
import QuestionnaireProvider from "./context/QuestionnaireProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./components/Routes";

function App() {
  return (
    <>
      <QuestionnaireProvider>
        <Router>
          <Routes />
        </Router>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </QuestionnaireProvider>
    </>
  );
}
export default App;
