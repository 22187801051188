import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { AuthContextProvider } from "context/auth-context";
import { ProjectsDataProvider } from "context/ProjectsDataProvider";

ReactDOM.render(
  <AuthContextProvider>
    <ProjectsDataProvider>
      <App />
    </ProjectsDataProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);
reportWebVitals();
