import React from "react";
import { useLocation } from "react-router-dom";
import Background from "components/assets/svg/workspace.webp";

const PromoBanner = () => {
  const location = useLocation();
  const showPromoBanner = location.pathname === "/";

  if (!showPromoBanner) {
    return null;
  }
  return (
    <section
      className="promo-banner-cta section-padding bg-overlay bg-cover"
      style={{ backgroundImage: `url('${Background}')` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="text-white">
              Looking to scale your business? <br />
              Feel free to contact us
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PromoBanner;
